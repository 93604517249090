/*******************************
   	All Responsive Code
   *******************************/

@media (min-width: 2401px) {
  .banner {
    background-size: 60% 100%;
  }
}

@media (max-width: 2400px) {
  .banner {
    background-size: 60% 100%;
  }
}

@media (max-width: 1920px) {
  .banner {
    background-size: 60% auto;
  }
}

@media (max-width: 1399px) {
  .banner {
    background-size: 72% auto;
    padding: 95px 0px 50px !important;
    font-size: 20px;
  }

  .testomonial .section-head {
    padding-left: 0%;
  }

  .testomonial .owl-carousel .owl-nav .owl-prev {
    left: 20px;
  }

  .testomonial .owl-carousel .owl-nav .owl-next {
    left: 70px;
  }

  .banner .car {
    animation: none;
    right: 0;
    bottom: 20%;
  }
}

@media (max-width: 1199px) {
  #scrollUp {
    right: 4% !important;
  }

  .main-menu .navbar #navbarNavDropdown ul.navbar-nav li.nav-item {
    margin-left: 20px;
  }

  .banner {
    background-size: 77% auto;
  }

  .testomonial .section-head {
    padding-bottom: 60px;
  }

  .testomonial .owl-carousel .owl-nav .owl-next,
  .testomonial .owl-carousel .owl-nav .owl-prev {
    top: 90%;
  }

  .loon .section-head {
    text-align: center;
  }

  .loon ul.list li.list-item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    /* justify-content: center; */
  }

  .footer .footer-box h4.lasthead {
    margin-top: 30px;
    padding-bottom: 0px;
  }

  .banner {
    background: none;
  }

  .banner .car {
    display: none;
  }
}

@media (max-width: 991px) {
  .main-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: -webkit-linear-gradient(
      45deg,
      rgba(144, 62, 255, 1) 0%,
      rgba(62, 25, 255, 1) 100%
    );
    background: linear-gradient(
      45deg,
      rgba(144, 62, 255, 1) 0%,
      rgba(62, 25, 255, 1) 100%
    );
    z-index: 99999;
    padding: 10px 0 10px;
  }

  .main-menu .navbar .navbar-toggler:focus {
    box-shadow: 0;
  }

  .main-menu .navbar #navbarNavDropdown ul.navbar-nav {
    height: 350px;
    overflow: auto;
  }

  .main-menu .navbar #navbarNavDropdown ul.navbar-nav {
    margin-left: auto;
    -webkit-box-align: start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
  }

  .main-menu .navbar #navbarNavDropdown ul.navbar-nav li.nav-item {
    margin-left: 0;
    margin-top: 10px;
  }

  .main-menu
    .navbar
    #navbarNavDropdown
    ul.navbar-nav
    li.nav-item
    a.nav-link:before {
    top: calc(100% + 0px);
  }

  h1.head {
    font-size: 35px !important;
    line-height: 60px !important;
  }

  .counter .page-counter .counter-item h2.title,
  .counter .page-counter .counter-item h2.title span,
  h2.title {
    font-size: 35px !important;
    line-height: 45px !important;
  }

  .feature .ster-box .content h3.step-head {
    font-size: 28px;
    line-height: 38px;
  }

  .loon {
    padding: 60px 0px 60px;
  }

  .calculate {
    padding: 60px 0px 50px;
  }

  .calculate .total-box .price-box {
    top: unset;
    position: relative !important;
  }

  .calculate .total-box .price-box .contant {
    margin-top: 40px;
  }

  .feature .section-head {
    padding-top: 0px;
    margin-bottom: 0px;
  }

  .feature {
    /* padding: 120px 0px 120px; */
  }

  .feature .ster-box .pic img.step {
    max-width: 70%;
    margin: 0px auto 0px;
  }

  .feature .ster-box .content,
  .feature .ster-box .pic {
    text-align: center;
  }

  .feature .ster-box:after {
    width: 100%;
  }

  .footer .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .footer .footer-box,
  .footer .footer-box .social-style {
    text-align: center;
  }

  .footer .footer-bottom .social-style {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .footer img.bg {
    display: none;
  }
}

@media (max-width: 575px) {
  .faq .fdot-1,
  .faq .fdot-2,
  .faq .fdot-3 {
    display: none;
  }

  .banner .hero-area .banner-content .button-box {
    font-size: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .banner .hero-area .banner-content .button-box a.one {
    margin-right: 0px;
    /* margin-top: 50px; */
  }

  .calculate .total-box .price-box .car {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .calculate .total-box .price-box .car h3.subtitle,
  .calculate .total-box .price-box .car p.text,
  .calculate .total-box .price-box .car h4.lasthead {
    padding-bottom: 10px;
  }

  .calculate .total-box .price-box {
    padding: 20px 10px 20px;
  }

  .banner .hero-area .banner-content {
    text-align: center;
  }
}

@media (max-width: 414px) {
  .button-2 {
    height: auto;
    /* padding: 8px 20px 8px; */
  }

  .loon ul.list li.list-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .loon ul.list li.list-item .thumb {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .loon ul.list li.list-item .content {
    text-align: center;
  }
}

@media (min-width: 991px) {
  .team-wrapper {
    // margin-top: 76em !important;
  }
}
@media (min-width: 991px) {
  .team-wrapper {
    // margin-top: 76em !important;
  }
}

@media (min-width: 1280px) {
  .team-wrapper {
    // margin-top: 84em !important;
  }
}

@media (min-width: 300px) {
  .plan-localization,
  .plan-deadline {
    top: 0;
    font-size: 18px !important;
    padding: 0;
    display: flex;
    align-self: center;
    justify-content: center;
  }
  .plan-deadline {
    width: 65%;
    top: -2em !important;
  }
  .plan-localization {
    text-align: center;
    background: #9039d8;
    color: #fff;
    width: 45%;
    border-radius: 0px 0px 25px 25px;
    position: absolute;
    top: 0.8em;
  }

  .contact-text-fixed {
    width: 95%;
    font-size: 14px !important;
  }

  input,
  textarea {
    border: 2px solid #7c8bff;
    /* border: 2px solid #6274fd; */
    /* font-family: 'Josefin Sans', sans-serif; */
    border-radius: 15px !important;
    padding: 0.7em;
    font-size: 16px;
    color: black;
  }

  #contact_form {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .margin-fixed {
    margin-top: 50px;
  }
  .feature {
    padding: 0;
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .plan-deadline {
    font-size: 45px !important;
    text-align: center;
    background: #9039d8;
    color: #fff;
    width: 65%;
    border-radius: 25px;
    box-shadow: 0px -2px 9px -1px #3d3d3d;
    position: absolute;
    top: -0.7em !important;
  }

  .plan-table {
    margin-top: 6em;
    font-size: 20px;
    font-weight: 700;
    width: 85%;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .plan-localization,
  .plan-deadline {
    font-size: 35px !important;
  }
  .plan-deadline {
    top: -1em !important;
  }

  .feature {
    padding: 120px 0px 120px;
    // margin-top: 35em;
  }
}

@media (min-width: 1280px) {
  .plan-localization,
  .plan-deadline {
    font-size: 35px;
  }
  .feature {
    padding: 0;
    // margin-top: 42em;
  }
}

@media (min-width: 200px) {
  tbody {
    width: 100%;
    font-size: 17px;
    padding: 6px;
  }
  .button-box {
    padding: -20em;
    margin: 0;
  }

  .hero-img {
    border-radius: 35px;
  }

  .counter {
    padding: 0;
  }

  .collapsed {
    font-size: 17px;
  }

  .faq
    .faq-box
    .accordion
    .accordion-item
    .accordion-header
    button.accordion-button {
    position: relative;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 19px;
    line-height: 32px;
    font-weight: 600;
    color: #3b368c;
    border-radius: 0;
    border: none;
    outline: none !important;
    padding: 20px 50px 20px 20px;
  }

  .banner .hero-area .banner-content h1.head {
    color: #33276a;
    padding: 10px 0px 8px;
  }

  /* .circles {
	  margin-left: auto;
	  margin-right: auto;
	  display: block;
	  width: 85%;
	}
	.circle {
	  position: absolute;
	  border-radius: 50%;
	  background: radial-gradient(var(--brighterPurple), var(--darkerPurple));
	  z-index: -1;
	}
  
  
	.circle-1 {
	  position: relative;
	  width: 70px;
	  height: 70px;
	  top: -190px;
	  left: -23px;
	}
	.circle-2 {
	  position: relative;
	  width: 100px;
	  height: 100px;
	  bottom: 135px;
	  right: -190px;
	} */

  .offers-img {
    max-width: 40em;
    margin-left: 20px;
    margin-top: 15em;
    border-radius: 30px;
  }

  .counter .page-counter .thmb {
    margin-right: 25px !important;
    max-width: 100px !important;
  }
}

@media (min-width: 375px) {
  .counter .page-counter .thmb {
    margin-right: 30px;
    max-width: 100px;
  }
}

@media (min-width: 768px) {
  .plan-localization,
  .plan-deadline {
    font-size: 25px;
    padding: 0;
  }

  .plan-deadline {
    font-size: 45px;
    text-align: center;
    background: #9039d8;
    color: #fff;
    width: 55%;
    border-radius: 25px;
    box-shadow: 0px -2px 9px -1px #3d3d3d;
    position: absolute;
    top: -0.7em;
  }

  .plan-localization {
    font-size: 30px;
    text-align: center;
    background: #9039d8;
    color: #fff;
    width: 38%;
    border-radius: 0px 0px 25px 25px;
    position: absolute;
    top: 0.8em;
  }

  .contact-text-fixed {
    width: 95%;
    font-size: 18px !important;
  }
  #contact_form {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  input,
  textarea {
    border-radius: 15px;
    font-size: 21px;
    color: black;
  }
  .margin-fixed {
    margin-top: 50px;
  }

  .hero-img {
    width: 80%;
    height: 200px;
    border-radius: 40px;
    margin-top: -2em;
  }

  .circles {
    height: 10em;
    width: 400px;
    border-radius: 0px;
    position: relative;
    left: 12em;
    top: -3em;
  }

  .circle {
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(var(--brighterPurple), var(--darkerPurple));
  }

  .circle-1 {
    height: 90px;
    width: 90px;
    top: -60px;
    left: -35px;
    opacity: 0.75;
    z-index: -2;
  }

  .circle-2 {
    height: 120px;
    width: 120px;
    bottom: 5px;
    right: 1.9em;
    opacity: 0.9;
    z-index: -2;
  }

  .hero-wrapper {
    display: block;
  }

  .faq
    .faq-box
    .accordion
    .accordion-item
    .accordion-header
    button.accordion-button {
    font-size: 22px;
  }

  .banner {
    padding: 150px 0px 0px;
  }
}

@media (min-width: 1024px) {
  .circles {
    display: flex;
    width: 100%;
  }

  .circles {
    height: 12em;
    width: 500px;
    border-radius: 75px;
    position: relative;
    left: 24em;
    top: -4em;
  }

  .circle {
    position: absolute;
    border-radius: 50%;
  }

  .circle-1 {
    height: 130px;
    width: 130px;
    top: -80px;
    left: -50px;
    opacity: 0.75;
  }

  .circle-2 {
    height: 170px;
    width: 170px;
    bottom: -50px;
    right: 40px;
    opacity: 0.9;
  }

  .hero-wrapper {
    display: block;
  }

  .hero-img {
    width: 80%;
    height: 270px;
    border-radius: 45px;
    margin-top: -2em;
  }
}

@media (min-width: 1200px) {
  .hero-wrapper {
    display: flex;
  }

  .hero-img {
    /* background-image: url(/assets/img/hero-img.png); */
    background-repeat: no-repeat;
    object-fit: fill;
    height: 15em;
    width: 90%;
    border-radius: 75px;
    z-index: 12;
  }

  img {
    z-index: 102020;
  }

  .circles {
    height: 20em;
    width: 90%;
    border-radius: 75px;
    position: relative;
    left: 9em;
    top: 15em;
    transform: translate(-50%, -50%);
  }

  .circle {
    position: absolute;
    border-radius: 50%;
  }

  .circle-1 {
    height: 160px;
    width: 160px;
    top: -100px;
    left: -70px;
    z-index: -1;
    opacity: 0.75;
  }

  .circle-2 {
    height: 230px;
    width: 230px;
    bottom: 40px;
    right: -30px;
    z-index: -1;
    opacity: 0.9;
  }
}

@media (min-width: 1400px) {
  .hero-img {
    height: 25em;
    width: 100%;
  }

  .circles {
    margin-top: 2em;
    left: 16em;
    width: 70em;
  }

  .circle-1 {
    height: 190px;
    width: 190px;
  }

  .circle-2 {
    height: 260px;
    width: 260px;
    bottom: -140px;
    right: -100px;
    z-index: -1;
    opacity: 0.9;
  }

  h3.subtitle {
    margin-top: 10px;
  }

  // .counter {
  //   margin-top: 7em;
  // }
}
