@import './bootstrap.min.scss';
// @import './font-awesome-5.10.2.min.scss';
@import './normalize.scss';
@import './responsive.scss';
@import './main.scss';

@import './plugin/animate.scss';
@import './plugin/jqurey-ui.min.scss/';
@import './plugin/magnific-popup.scss';
@import './plugin/nice-select.scss';
@import './plugin/owl.carousel.min.scss';

// @import './main/_global.scss';
// @import './main/_variables.scss';
// @import './main/_normalize.scss';

// @import './pages/_index-html.scss';
// @import './pages/_range.scss';
