/*============================================ 
Template Name: Gooland
Version: 1.0
Author: pixelaxis
Author URI: https://themeforest.net/user/pixelaxis/portfolio
Description: Bitcoin Investment HTML Template


/*======== Table of Css Content ==========*/
/* Typography */
/* variables */
/* Normalize */
/* input and button type focus outline disable */
/* Global style */
/* Preloader Css */
/* Banners Start */
/* Main-menu Start */
/* Banners Start */
/* Hero_area Start */
/* Compare Start */
/* Feature Start */
/* upper-content Start */
/* Deposit Start */
/* Earningpartners Start */
/* Deposit Amount Start */
/* Earning Start */
/* Transaction Start */
/* How-use Start */
/* Counter Start */
/* Process Start */
/* testomonial Start */
/* Footer Start */
/* Scroll To Top Start *
/* 
=========================
=============================
 */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;600;700;800&display=swap');
/* 
=========================
font-family: 'Josefin Sans', sans-serif;
font-family: 'Open Sans', sans-serif;
=============================
 */
/* Typography */
/* Normalize  */
.plan-container ul {
  padding-left: 2rem;
  li{
    list-style-type: disc;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
  font: inherit;
  padding: 0;
  margin: 0;
}

body {
  font: inherit;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 1.556;
  font-weight: 400;
}

.help-block.error {
  margin-bottom: 5px;
}

h1 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 80px;
  line-height: 90px;
  font-weight: 700;
}

h2 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
}

h3 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}

h4 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}

p,
a,
li,
span {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 1.556;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

a {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
}

.nav-item a i {
  font-size: 18px;
  margin-right: 5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Josefin Sans', sans-serif;
  margin: 0;
  padding: 0;
}

p {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

a {
  text-decoration: none;
  font-weight: 400;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

/*input and button type focus outline disable*/
input[type='text']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='number']:focus,
textarea:focus,
input[type='button']:focus,
input[type='reset']:focus,
input[type='date']:focus,
input[type='submit']:focus,
select:focus,
button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Global style */
.button-1 {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 10px;
  width: auto;
  padding: 0px 30px 0px;
  height: 50px;
  line-height: 50px;
  /* -webkit-box-shadow: 0px 16px 24px 0px rgba(194, 22, 220, 0.51);
  box-shadow: 0px 16px 24px 0px rgba(194, 22, 220, 0.51);
  background: linear-gradient(-45deg, #893bff 0%, #fc76b3 100%); */
  background: #765af0 !important;
  text-align: center;
}

.button-1:hover {
  color: #ffffff;
  transition: 0.4s;
  transition: 0.4s;
  background-color: var(--brighterPurple) !important;
  /* background: linear-gradient(-45deg, #fc76b3 0%, #893bff 100%); */
}

.button-2 {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  border-radius: 5px;
  width: auto;
  padding: 23px 30px;
  height: 75px;
  line-height: 25px;
  background: var(--darkerPurple);
}
.button-2 span {
  display: block;
  font-size: 16px;
  color: #fff;
}

.p .wow {
  /* width: 30%; */
}

.button-2:hover {
  color: #ffffff;
  transition: 0.4s ease-in-out;
  background: var(--brighterPurple);
}

.navbar-toggler {
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
}

.navbar-toggler:focus {
  outline: 0px;
}

h1.head {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 80px;
  line-height: 90px;
  font-weight: 700;
}

h2.title {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
}

h3.subtitle {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}

h4.lasthead {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}

p.text {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

/* Preloader Css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #903eff 0%, #3e19ff 100%);
  overflow: hidden;
}
.preloader .preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.preloader .preloader-inner .preloader-icon {
  width: 72px;
  height: 72px;
  display: inline-block;
  padding: 0px;
  z-index: 1;
}
.preloader .preloader-inner .preloader-icon span {
  position: absolute;
  display: inline-block;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  background: #fff;
  -webkit-animation: preloader-fx 1.6s linear infinite;
  animation: preloader-fx 1.6s linear infinite;
}
.preloader .preloader-inner .preloader-icon span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

.position-fix {
  /* margin-bottom: 0vh; */
}

@keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}
@-webkit-keyframes pulsani {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.002);
    transform: scale(1.002);
  }
  100% {
    -webkit-transform: scale(1.004);
    transform: scale(1.004);
    opacity: 0;
  }
}
@keyframes pulsani {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.002);
    transform: scale(1.002);
  }
  100% {
    -webkit-transform: scale(1.004);
    transform: scale(1.004);
    opacity: 0;
  }
}
@-webkit-keyframes trantani {
  0% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  50% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}
@keyframes trantani {
  0% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  50% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  100% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}
@-webkit-keyframes upornis {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes upornis {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  50% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  50% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes gari {
  0% {
    bottom: 50%;
    right: -25%;
  }
  4% {
    bottom: 46%;
    right: -20%;
  }
  9% {
    bottom: 43%;
    right: -17%;
  }
  13% {
    bottom: 40%;
    right: -11%;
  }
  17% {
    bottom: 41%;
    right: -13%;
  }
  22% {
    bottom: 39%;
    right: -9%;
  }
  26% {
    bottom: 35%;
    right: -5%;
  }
  31% {
    bottom: 30%;
    right: 0%;
  }
  35% {
    bottom: 25%;
    right: 5%;
  }
  40% {
    bottom: 20%;
    right: 9%;
  }
  44% {
    bottom: 14%;
    right: 12%;
  }
  49% {
    bottom: 8%;
    right: 18%;
  }
  53% {
    bottom: 3%;
    right: 20%;
  }
  58% {
    bottom: -3%;
    right: 21%;
  }
  62% {
    bottom: -6%;
    right: 22%;
  }
  67% {
    bottom: -9%;
    right: 23%;
  }
  71% {
    bottom: -11%;
    right: 24%;
  }
  76% {
    bottom: -20%;
    right: 30%;
  }
  80% {
    bottom: -24%;
    right: 34%;
  }
  85% {
    bottom: -30%;
    right: 36%;
  }
  90% {
    bottom: -37%;
    right: 39%;
  }
  100% {
    bottom: -41%;
    right: 42%;
  }
}
@keyframes gari {
  0% {
    bottom: 50%;
    right: -25%;
  }
  4% {
    bottom: 46%;
    right: -20%;
  }
  9% {
    bottom: 43%;
    right: -17%;
  }
  13% {
    bottom: 40%;
    right: -11%;
  }
  17% {
    bottom: 41%;
    right: -13%;
  }
  22% {
    bottom: 39%;
    right: -9%;
  }
  26% {
    bottom: 35%;
    right: -5%;
  }
  31% {
    bottom: 30%;
    right: 0%;
  }
  35% {
    bottom: 25%;
    right: 5%;
  }
  40% {
    bottom: 20%;
    right: 9%;
  }
  44% {
    bottom: 14%;
    right: 12%;
  }
  49% {
    bottom: 8%;
    right: 18%;
  }
  53% {
    bottom: 3%;
    right: 20%;
  }
  58% {
    bottom: -3%;
    right: 21%;
  }
  62% {
    bottom: -6%;
    right: 22%;
  }
  67% {
    bottom: -9%;
    right: 23%;
  }
  71% {
    bottom: -11%;
    right: 24%;
  }
  76% {
    bottom: -20%;
    right: 30%;
  }
  80% {
    bottom: -24%;
    right: 34%;
  }
  85% {
    bottom: -30%;
    right: 36%;
  }
  90% {
    bottom: -37%;
    right: 39%;
  }
  100% {
    bottom: -41%;
    right: 42%;
  }
}
:root {
  --bg: #e3e4e8;
  --bgT: #e3e4e800;
  --fg: #17181c;
  --inputBg: #fff;
  --handleBg: #255ff4;
  --handleDownBg: #0b46da;
  --handleTrackBg: #5583f6;
  font-size: calc(16px + (32 - 16) * (100vw - 320px) / (2560 - 320));
  --surface-color: #fff;
  --card-color: #e7ebf0;
  --curve: 40;
  --brighterPurple: #9039d8;
  --darkerPurple: #6342ff;
}

input {
  color: var(--fg);
  /* font: 1em/1.5 'Hind', sans-serif !important; */
  font-family: 'Open Sans', sans-serif !important;
  font-size: 20px !important;
  font-weight: normal;
}

.range,
.range__counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

input,
.range__input,
.range__counter-sr {
  width: 100%;
}

.range:not(:last-child) {
  margin-bottom: 1.5em;
}

.range input[type='range'],
.range input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}

/* .range input[type='range'],
.range__input-fill {
  border-radius: 0.25em;
  height: 0.5em;
} */

/* .range input[type='range'] {
  background-color: var(--inputBg);
  display: block;
  margin: 0.5em 0;
  padding: 0;
} */

.range input[type='range']:focus {
  outline: transparent;
}

/* Styling the slider handle. */
/* .range input[type='range']::-webkit-slider-thumb {
  background-color: var(--handleBg);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  -webkit-transition: background 0.1s linear;
  transition: background 0.1s linear;
  width: 1.5em;
  height: 1.5em;
  z-index: 1;
} */

.range input[type='range']::-moz-range-thumb {
  background-color: var(--handleBg);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transform: translateZ(1px);
  -moz-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
  width: 1.5em;
  height: 1.5em;
  z-index: 1;
}

/* .range input[type='range']::-moz-focus-outer {
  border: 0;
} */

.range__input,
.range__input-fill,
.range__counter-column,
.range__counter-digit {
  display: block;
}

.range__input,
.range__counter {
  position: relative;
}

.range__input {
  margin-right: 0.375em;
}

.range__input:active input[type='range']::-webkit-slider-thumb,
.range input[type='range']:focus::-webkit-slider-thumb,
.range input[type='range']::-webkit-slider-thumb:hover {
  background-color: var(--handleDownBg);
}

.range__input:active input[type='range']::-moz-range-thumb,
.range input[type='range']:focus::-moz-range-thumb,
.range input[type='range']::-moz-range-thumb:hover {
  background-color: var(--handleDownBg);
}

.range__input-fill,
.range__counter-sr {
  position: absolute;
  left: 0;
}

.range__input-fill {
  background-color: var(--handleTrackBg);
  pointer-events: none;
  top: calc(50% - 0.25em);
}

.range__counter,
.range__counter-digit {
  height: 1.5em;
}

.range__counter {
  margin: auto 0;
  overflow: hidden;
  text-align: center;
}

.range__counter-sr {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(var(--bg)),
    color-stop(0.3em, var(--bgT)),
    to(var(--bg))
  );
  background-image: linear-gradient(
    var(--bg),
    var(--bgT) 0.3em 1.2em,
    var(--bg)
  );
  color: transparent;
  letter-spacing: 0.06em;
  top: 0;
  text-align: right;
  z-index: 1;
}

.range__counter-column {
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  width: 0.66em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.range__counter-column--pause {
  -webkit-transition: none;
  transition: none;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: #2e3138;
    --bgT: #2e313800;
    --fg: #e3e4e8;
    --inputBg: #17181c;
  }
}
/* Main-menu Start */
.main-menu {
  width: 100%;
  z-index: 9999;
  padding: 20px 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.main-menu .navbar {
  padding: 0px 0px;
  padding: 0px 0px;
  padding: 0px 0px;
  padding: 0px 0px;
  padding: 0px 0px;
  padding: 0px 0px;
  padding: 0px 0px;
  padding: 0px 0px;
  padding: 0rem 0rem;
}
.main-menu .navbar #navbarNavDropdown {
  margin: 0;
}
.main-menu .navbar #navbarNavDropdown ul.navbar-nav {
  margin-left: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.main-menu .navbar #navbarNavDropdown ul.navbar-nav li.nav-item {
  margin-left: 30px;
}
.main-menu .navbar #navbarNavDropdown ul.navbar-nav li.nav-item:first-child {
  margin-left: 0;
}
.main-menu .navbar #navbarNavDropdown ul.navbar-nav li.nav-item a.nav-link {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: black;
  font-weight: 700;
  text-transform: capitalize;
  padding-right: 0;
  padding-left: 0;
  position: relative;
}
.nav-fixed .navbar #navbarNavDropdown ul.navbar-nav li.nav-item a.nav-link {
  color: white;
}

.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  a.nav-link:before {
  position: absolute;
  content: '';
  top: calc(100% + 22px);
  left: 0;
  width: 0%;
  height: 2px;
  background: #ffffff;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  a.nav-link:hover:before {
  width: 100%;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .nav-link.active::before {
  width: 100%;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .language-select
  .nice-select {
  border: 0;
  width: 65px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600 !important;
  color: #000000;
  height: auto;
  padding: 3px 0px 0px;
  background: transparent;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .language-select
  .nice-select:after {
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  width: 10px;
  height: 10px;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .language-select
  .nice-select
  span {
  font-weight: 600 !important;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .language-select
  .nice-select
  .list {
  width: 100px;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .language-select
  .nice-select
  .list
  li {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600 !important;
  color: #33276a;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .nav-link.button-1 {
  text-transform: uppercase;
  width: 160px;
  font-size: 16px;
  color: #fff;
  line-height: 50px;
  /* -webkit-box-shadow: 0px 16px 24px 0px rgba(194, 22, 220, 0.51);
  box-shadow: 0px 16px 24px 0px rgba(194, 22, 220, 0.51); */
  background-color: var(--darkerPurple);
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .nav-link.button-1:hover,
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .nav-link.button-1.active {
  color: #fff;
}
.main-menu
  .navbar
  #navbarNavDropdown
  ul.navbar-nav
  li.nav-item
  .nav-link.button-1:before {
  display: none;
}

.nav-fixed.a.nav-link {
  color: white;
}

.main-menu.nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  /* background-color: var(--brighterPurple); */
  background: linear-gradient(45deg, #903eff 0%, #3e19ff 100%);
  z-index: 99999;
}

.navbar-toggler:focus {
  -webkit-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}

.main-menu .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.95%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

/* Banners Start */
.banner {
  background-color: #fff;
  background-size: unset;
  background-repeat: no-repeat;
  background-position: top 0px right 0px;
  padding: 150px 0px 130px;
  position: relative;
  overflow: hidden;
}
.banner .car {
  position: absolute;
  -webkit-animation: gari 15s linear infinite;
  animation: gari 15s linear infinite;
}
.banner .hero-area .banner-content h3.subtitle {
  color: #6342ff;
}
.banner .hero-area .banner-content h1.head {
  color: #33276a;
  padding: 12px 0px 16px;
}
.banner .hero-area .banner-content p.text {
  color: #5d5779;
  font-size: 20px;
  line-height: 30px;
}
.banner .hero-area .banner-content .button-box {
  padding: 0px 0px 24px;
}
.banner .hero-area .banner-content .button-box a.one {
  margin-right: 0px;
}
.banner .hero-area .banner-content .button-box a.video-button {
  width: 51px;
  height: 51px;
  line-height: 51px;
  text-align: center;
  border-radius: 50%;
  background: #324fb0;
  position: relative;
  color: #ffffff;
}
.banner .hero-area .banner-content .button-box a.video-button i {
  margin-left: 5px;
  color: #ffffff !important;
}
.banner .hero-area .banner-content .button-box a.video-button:before {
  background: rgba(50, 79, 176, 0.161);
  width: 100px;
  height: 100px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  border-radius: 33.33% 50%;
  -webkit-animation: rotate 8s linear infinite;
  animation: rotate 8s linear infinite;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  top: -50%;
  left: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  content: '';
}
.banner .hero-area .banner-content .button-box a.video-button:after {
  background: rgba(50, 79, 176, 0.161);
  height: 73px;
  width: 73px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  border-radius: 33.33% 50%;
  -webkit-animation: rotate 4s linear infinite;
  animation: rotate 4s linear infinite;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  top: -25%;
  left: -25%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  content: '';
}
.banner .hero-area .banner-content p.rate {
  font-size: 16px;
  line-height: 26px;
  color: #5d5779;
}

/* Counter Start */
// .counter {
//   padding: 70px 0px 30px;
// }
.counter .page-counter {
  padding: 30px 0px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.counter .page-counter .thmb {
  margin-right: 30px;
  max-width: 150px;
}
.counter .page-counter .counter-item h2.title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 400;
}
.counter .page-counter .counter-item h2.title span {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 50px;
  line-height: 60px;
  font-weight: 400;
}
.counter .page-counter .counter-item p.text {
  color: #33276a;
}
.counter .page-counter.four:after {
  display: none;
}

/* section-head Start */
.section-head {
  margin-bottom: 26px;
}
.section-head h3.subtitle {
  color: #6342ff;
  display: flex;
  justify-content: center;
}
.section-head h2.title {
  color: #33276a;
  padding: 21px 0 16px;
}
.section-head p.text {
  color: #33276a;
}
.section-head a.button.button-1 {
  margin-top: 33px;
  min-width: 220px;
  -webkit-box-shadow: 0px 7px 13px 0px rgba(153, 99, 255, 0.59);
  box-shadow: 0px 7px 13px 0px rgba(153, 99, 255, 0.59);
}

/* Manage Start */
.loon {
  padding: 60px 0px 120px;
}
.loon .section-head {
  margin-bottom: 58px;
}
.loon .section-head h2.title {
  color: #33276a;
  padding: 21px 0 16px;
}
.loon .section-head p.text {
  color: #5d5779;
}
.loon ul.list li.list-item {
  margin-top: 40px;
  padding: 25px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}
.loon ul.list li.list-item .thumb {
  margin-right: 30px;
}
.loon ul.list li.list-item .content h4.lasthead {
  color: #33276a;
  font-weight: 600;
}
.loon ul.list li.list-item .content p.text {
  color: #5d5779;
}
.loon .pic {
  margin-left: 30px;
}

/* Calculate Start */
.calculate {
  /* padding: 120px 0px 480px; */
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.calculate .section-head {
  margin-bottom: 26px;
}
.calculate .section-head h2.title {
  padding: 15px 0 4px;
}
.calculate .total-box {
  margin-top: 30px;
  position: relative;
}
.calculate .total-box .price-box {
  top: 50%;
  width: 100%;
  // position: absolute;
  padding: 40px 40px 40px;
  background-color: white;
  -webkit-box-shadow: -6.251px -35.453px 29px 0px rgba(0, 10, 221, 0.12);
  box-shadow: -6.251px -35.453px 29px 0px rgba(0, 10, 221, 0.12);
}
.calculate .total-box .price-box .contant {
  padding: 60px 20px 40px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.calculate .total-box .price-box .contant h3.subtitle {
  color: #fff;
}
.calculate .total-box .price-box .contant h2.title {
  padding: 40px 0px 28px;
  color: #fc76b3;
  text-shadow: 0px 10px 9px rgba(242, 64, 94, 0.46);
  -moz-transform: matrix(1.3403294969, 0, 0, 1.3403294969, 0, 0);
  -webkit-transform: matrix(1.3403294969, 0, 0, 1.3403294969, 0, 0);
  -ms-transform: matrix(1.3403294969, 0, 0, 1.3403294969, 0, 0);
}
.calculate .total-box .price-box .contant .last-box {
  border-top: 1px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 33px 0px 33px;
}
.calculate .total-box .price-box .contant .last-box .box p.text {
  color: #fff;
}
.calculate .total-box .price-box .contant .last-box .box h4.taka {
  color: #3ef307;
}
.calculate .total-box .price-box .invest-range-area {
  padding: 40px 0px 68px;
}
.calculate .total-box .price-box .invest-range-area .invest-amount {
  width: 100%;
  position: relative;
}
.calculate
  .total-box
  .price-box
  .invest-range-area
  .invest-amount
  .invest-range-slider {
  background: #dee7fc;
  border: none;
  height: 10px;
  border-radius: 5px;
}
.calculate
  .total-box
  .price-box
  .invest-range-area
  .invest-amount
  .ui-slider-range-min {
  background: linear-gradient(
    9deg,
    #503aff 0%,
    #6c49ff 3%,
    #8857ff 45%,
    #b871ff 82%,
    #e88aff 100%
  );
}
.calculate
  .total-box
  .price-box
  .invest-range-area
  .invest-amount
  .ui-state-default {
  height: 33px;
  width: 33px;
  border-radius: 50%;
  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  background: linear-gradient(-45deg, #facd68 0%, #fc76b3 100%);
  -webkit-box-shadow: 0px 3px 13px 0px rgba(41, 54, 150, 0.51);
  box-shadow: 0px 3px 13px 0px rgba(41, 54, 150, 0.51);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.calculate .total-box .price-box .invest-range-area .invest-amount:after,
.calculate .total-box .price-box .invest-range-area .invest-amount:before {
  position: absolute;
  top: 20px;
  font-size: 16px;
}
.calculate .total-box .price-box .invest-range-area .invest-amount:before {
  left: 0;
  content: attr(data-min);
}
.calculate .total-box .price-box .invest-range-area .invest-amount:after {
  right: 0;
  content: attr(data-max);
}
.calculate .total-box .price-box .invest-range-area .custom-button {
  width: 160px;
  outline: none;
}
.calculate .total-box .price-box .invest-range-area .main-amount {
  width: 100%;
}
.calculate .total-box .price-box .invest-range-area .main-amount {
  background: transparent;
  border: none;
  height: 20px;
  color: #33276a;
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}
.calculate .total-box .price-box .car {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.calculate .total-box .price-box .car h3.subtitle {
  color: #33276a;
}
.calculate .total-box .price-box .car p.text {
  color: #33276a;
}
.calculate .total-box .price-box .car .form-group {
  border: 1px solid #6a35ff;
  border-radius: 10px;
  background-color: rgba(0, 36, 255, 0.051);
  width: 214px;
  height: 64px;
  font-size: 24px;
  color: #33276a;
  font-weight: 600;
  padding: 0px 40px 0px;
}
.calculate .total-box .price-box .car .language-select {
  min-width: 164px;
  min-height: 47px;
  border: 1px solid #6a35ff;
  background-color: rgba(0, 36, 255, 0);
  border-radius: 10px;
}
.calculate .total-box .price-box .car .language-select .nice-select {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  font-size: 18px;
  line-height: 47px;
  color: #33276a;
  font-weight: 400 !important;
}
.calculate .total-box .price-box .car .language-select .nice-select:after {
  border-bottom: 2px solid #8f8e8e;
  border-right: 2px solid #8f8e8e;
  width: 10px;
  height: 10px;
}
.calculate .total-box .price-box .car .language-select .nice-select span {
  font-size: 18px;
  line-height: 28px;
  color: #33276a;
  font-weight: 400 !important;
}
.calculate .total-box .price-box .car .language-select .nice-select .list {
  width: 100%;
}
.calculate .total-box .price-box .car .language-select .nice-select .list li {
  font-size: 18px;
  line-height: 28px;
  color: #33276a;
  text-align: center;
}

/* Calculate End */
/* feature Start */

.feature .section-head {
  margin-bottom: 0px;
}
.feature .section-head h2.title {
  padding: 15px 0px 4px;
}
.feature .ster-box {
  position: relative;
  padding: 30px 0px 30px;
  margin: 60px 0px 0px;
}
.feature .ster-box:after {
  position: absolute;
  top: 0px;
  left: 50%;
  height: 100%;
  width: 80%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: '';
  background: #f3f3ff;
  z-index: -1;
}
.feature .ster-box .content h4.lasthead {
  color: #6342ff;
}
.feature .ster-box .content h3.step-head {
  color: #33276a;
  font-size: 36px;
  line-height: 46px;
  padding: 11px 0px 15px;
}
.feature .ster-box .content p.text {
  color: #33276a;
}
.feature .ster-box.two:after {
  background: #fff3f2;
}
.feature .ster-box.three:after {
  background: #f2fffe;
}
.feature .ster-box.four:after {
  background: #fff2fe;
}

/* testomonial Start */
.testomonial {
  padding: 120px 0px 120px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: relative;
}
.testomonial .section-head {
  padding-left: 40%;
  position: relative;
  z-index: 3;
  margin-bottom: 0px;
}
.testomonial .section-head .subtitle {
  color: #fff;
}
.testomonial .section-head h2.title {
  color: #fff;
  padding: 16px 0 0px;
}
.testomonial .testo-box {
  position: unset;
}
.testomonial .testo-box .single .content-box {
  padding: 50px 20px 50px;
  border-radius: 15px;
  background-color: white;
  -webkit-box-shadow: 1.877px 11.852px 25.92px 1.08px rgba(50, 27, 183, 0.1);
  box-shadow: 1.877px 11.852px 25.92px 1.08px rgba(50, 27, 183, 0.1);
}
.testomonial .testo-box .single .content-box .quate i {
  font-size: 28px;
  line-height: 38px;
  color: #321cb7;
}
.testomonial .testo-box .single .content-box p.text {
  color: #3b368c;
  padding: 17px 0px 35px;
}
.testomonial .testo-box .single .content-box .last-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.testomonial .testo-box .single .content-box .last-content .thumb {
  width: 50px !important;
  margin-right: 20px;
}

.thumb img {
  max-width: 115px !important;
}

.testomonial .testo-box .single .content-box .last-content h4.lasthead {
  color: #3b368c;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
.testomonial .testo-box .single .content-box .last-content h4.lasthead span {
  font-family: 'Open Sans', sans-serif;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #3b368c;
}
.testomonial .owl-carousel .owl-nav .owl-prev {
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 20px;
  position: absolute;
  top: 82%;
  left: 17%;
  color: #ffffff;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0);
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-weight: 400;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 10px;
}
.testomonial .owl-carousel .owl-nav .owl-prev:hover {
  background: #fff;
  -webkit-box-shadow: 0.939px 5.926px 5.76px 0.24px rgba(255, 255, 255, 0.21);
  box-shadow: 0.939px 5.926px 5.76px 0.24px rgba(255, 255, 255, 0.21);
  color: #33276a;
}
.testomonial .owl-carousel .owl-nav .owl-next {
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 20px;
  position: absolute;
  top: 82%;
  left: 20%;
  color: #33276a;
  border-radius: 50%;
  background-color: white;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-weight: 400;
  -webkit-box-shadow: 0.939px 5.926px 5.76px 0.24px rgba(255, 255, 255, 0.21);
  box-shadow: 0.939px 5.926px 5.76px 0.24px rgba(255, 255, 255, 0.21);
  margin-left: 10px;
}

/* faq Start */
.faq {
  position: relative;
  padding: 50px 0 50px;
}
.faq .fdot-1 {
  position: absolute;
  top: 15%;
  left: 45%;
  max-width: unset;
  z-index: -1;
  -webkit-animation: trantani 8s linear infinite;
  animation: trantani 8s linear infinite;
}
.faq .fdot-2 {
  position: absolute;
  top: 6%;
  left: 65%;
  max-width: unset;
  z-index: -1;
  -webkit-animation: trantani 6s linear infinite;
  animation: trantani 6s linear infinite;
}
.faq .fdot-3 {
  position: absolute;
  top: 0%;
  left: 35%;
  max-width: unset;
  z-index: -1;
  -webkit-animation: trantani 4s linear infinite;
  animation: trantani 4s linear infinite;
}
.faq .fpic {
  margin-right: 30px;
  direction: rtl;
}
.faq .fpic .faq-img {
  max-width: unset;
}
.faq .section-head {
  margin-bottom: 26px;
}
.faq .section-head h2.title {
  padding: 0px 0px 16px;
}
.faq .section-head p.text > a {
  color: #3e19ff;
}
.faq .section-head p.text > a:hover {
  text-decoration: underline;
}
.faq .faq-box .accordion .accordion-item {
  outline: none;
  margin-top: 20px;
}
.faq .faq-box .accordion .accordion-item:first-child {
  margin-top: 34px;
}
.faq .faq-box .accordion .accordion-item .accordion-header {
  position: relative;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 9.272px 3.746px 38px 0px rgba(176, 163, 231, 0.26);
  box-shadow: 9.272px 3.746px 38px 0px rgba(176, 163, 231, 0.26);
}
.faq
  .faq-box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button {
  position: relative;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #3b368c;
  border-radius: 0;
  border: none;
  outline: none !important;
  padding: 20px 50px 20px 20px;
}
.faq
  .faq-box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button[aria-expanded='true'] {
  background: transparent;
  outline: none !important;
}
.faq
  .faq-box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
  box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
}
.faq
  .faq-box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button
  .icon {
  margin-right: 20px;
  z-index: 1;
}
.faq
  .faq-box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button:after {
  display: none;
}
.faq
  .faq-box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button:before {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #3b368c;
}
.faq
  .faq-box
  .accordion
  .accordion-item
  .accordion-header
  button.accordion-button[aria-expanded='true']:before {
  top: 35%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.faq .faq-box .accordion .accordion-item .accordion-collapse {
  border: none;
  border-top: 0;
  border-radius: 0px 0px 10px 10px;
  background-color: white;
  -webkit-box-shadow: 9.272px 3.746px 38px 0px rgba(176, 163, 231, 0.26);
  box-shadow: 9.272px 3.746px 38px 0px rgba(176, 163, 231, 0.26);
}
.faq .faq-box .accordion .accordion-item .accordion-collapse .accordion-body {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #33276a;
  font-weight: 400;
  padding: 24px 30px 26px 30px;
}

/* download Start */
.started {
  padding: 120px 0px 120px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.started .section-content h2.down-head {
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 15px;
}
.started .section-content p.text {
  color: white;
}

#scrollUp {
  right: 4% !important;
}
/* Footer Start */
.footer {
  padding: 120px 0px 0px;
  position: relative;
}
// .footer img.bg {
//   position: absolute;
//   display: block;
//   bottom: 0%;
//   right: -100px;
//   z-index: -1;
// }
.footer .footer-box .logo {
  padding-bottom: 10px;
}
.footer .footer-box p.text {
  padding-top: 8px;
  color: #5d5779;
}
.footer .footer-box h4.lasthead {
  color: #33276a;
  padding-bottom: 10px;
}
.footer .footer-box .footer-link li {
  color: #5d5779;
  padding-top: 8px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #5d5779;
}
.footer .footer-box .footer-link li a {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #5d5779;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.footer .footer-box .footer-link li a:hover {
  color: #6342ff;
  text-decoration: underline;
}
.footer .footer-bottom {
  margin-top: 117px;
  padding: 20px 0px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid rgba(104, 112, 197, 0.2);
}
.footer .footer-bottom .content p {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #5d5779;
}
.footer .footer-bottom .content p a {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #6342ff;
}
.footer .footer-bottom .content p a:hover {
  text-decoration: underline;
}
.footer .footer-bottom .social-style a {
  margin-left: 15px;
  background-color: #d5dafa;
  color: #8c99d6;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.footer .footer-bottom .social-style a:hover {
  border: 1px solid linear-gradient(24deg, #903eff 17%, #ea07fe 100%);
  background: linear-gradient(24deg, #903eff 17%, #ea07fe 100%);
  -webkit-box-shadow: -1.816px 3.564px 10px 0px rgba(60, 49, 172, 0.29);
  box-shadow: -1.816px 3.564px 10px 0px rgba(60, 49, 172, 0.29);
  color: rgba(255, 255, 255, 0.9);
}

/* Scroll To Top Start */
#scrollUp {
  position: fixed;
  display: none;
  height: 45px;
  width: 40px;
  /* right: 2%; */
  bottom: 3%;
  border-radius: 4px;
  background: linear-gradient(45deg, #903eff 0%, #3e19ff 100%);
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  z-index: 111500;
  color: black;
}

#scrollUp i.fas.fa-arrow-up {
  color: #fff;
  line-height: 45px;
}

/* Scroll To Top End */

.plan-header {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.plan-deadline {
  font-size: 45px;
  text-align: center;
  background: #9039d8;
  color: #fff;
  width: 65%;
  border-radius: 25px;
  box-shadow: 0px -2px 9px -1px #3d3d3d;
  position: absolute;
  top: -0.7em;
}

.plan-table {
  margin-top: 6em;
  font-size: 20px;
  font-weight: 700;
  width: 85%;
  text-align: center;
}

.table-row {
  border-bottom: solid 4px #a349ee;
}

.table-row td {
  padding: 25px;
}

/* tr:first-child {
  border-top: solid 5px black;
} */

/* cards team */
.team-container {
  width: 100%;
  /* margin-top: 90vh; */
}

/* #team{
  margin-top: 120vh;
} */

.contact-text-fixed {
  margin-left: auto;
  margin-right: auto;
}
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem 3rem;
  margin: 2rem 0.5vw;
  padding: 0;
  list-style-type: none;
  align-items: center;
}

.single-card {
  width: 300px;
  height: 550px;
  margin-bottom: -2rem;
  margin-left: auto;
  margin-right: auto;
}

.card {
  position: relative;
  display: block;
  height: 525px;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0px 0px 9px -7px black;
  color: black;
}

.card__image {
  width: 100%;
  height: 90%;
  object-fit: cover;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--card-color);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  /* display: flex; */
  align-items: center;
  gap: 1em;
  padding: 1.5em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--card-color);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
  width: 100%;
}

.card__header-text {
  display: flex;
  width: 100%;
}

.linkedin {
  color: #0a66c2;
  font-size: 33px;
  width: 20%;
  margin-top: 7px;
}

.card__status {
  width: 70%;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 99.3%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--card-color);
  d: path('M 40 80 c 22 0 40 -22 40 -40 v 40 Z');
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  width: 100%;
  font-size: 1em;
  color: #000000;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-size: 0.9em;
}

.card__status {
  font-size: 0.78em;
}

.wrapper-description {
  width: 100%;
  display: flex;
}

.card__description {
  background-color: var(--card-color);
  padding: 0 1.5em 1.5em;
  font-size: 13px;
  margin: 0;
  color: #1d1c1c;
  overflow: hidden;
  width: 100%;
}

.icon-description {
  width: 5%;
}

.icons {
  color: #0a66c2;
  font-size: 2em;
  margin-left: 15px;
}

.contact-form {
  display: flex;
  justify-content: center;
  text-align: left;
  width: 100%;
}

textarea {
  width: 100%;
  min-height: 300px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 20px !important;
}

.btn--normal {
  font-size: 0;
  padding: 15px 40px;
  border-radius: 17px;
  border: none;
  background: #566bff;
  color: #fff;
  box-shadow: -1px 0px 7px 2px #566bff;
  border-radius: 25px;
}

.btn--normal:hover {
  background: #462ad1;
  box-shadow: none;
  color: white;
  transition: all 0.6s;
}

/* .submit-btn-text{
  margin-top: 10px;
} */

.input-group {
  margin-top: 15px;
  font-size: 19px;
  font-weight: 700;
}

.wrapper-contact {
  text-align: center;
  background-color: #f8faff;
  padding: 1rem 0 4rem 0;
  box-shadow: -6.251px 35.453px 29px 0px #000add1f;
}

.error {
  color: #da0303 !important;
}

.faq-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.main-menu.nav-fixed .logo-light {
  display: block;
}

.main-menu .logo-dark {
  display: none;
}

.banner-content .text {
  padding-top: 15px;
}

.main-menu .navbar ul.navbar-nav a.nav-link,
.main-menu .navbar ul.navbar-nav .nav-item#kontakt a.nav-link {
  color: #ffffff !important;
}

@media (min-width: 992px) {
  .main-menu .navbar ul.navbar-nav a.nav-link {
    color: #282828 !important;
  }
  .main-menu.nav-fixed .navbar ul.navbar-nav a.nav-link {
    color: #ffffff !important;
  }
  .banner-content .text {
    padding-top: 0;
  }
  .main-menu .logo-light {
    display: none;
  }

  .main-menu.nav-fixed .logo-light {
    display: block;
  }

  .main-menu .logo-dark {
    display: block;
  }

  .main-menu.nav-fixed .logo-dark {
    display: none;
  }
}
